import React from "react";
import ReferencesStyle from "./References.style";
import SectionTitle from "../SectionTitle/SectionTitle";

import MagizhamImg from "../../assets/images/references/magizham.png";
import JnjpImg from "../../assets/images/references/jnjp.png";
import BrandNuImg from "../../assets/images/references/brand-nu.png";
import ZamaImg from "../../assets/images/references/zama.png";
import AnukulaImg from "../../assets/images/references/anukula.png";
import AdoreABooImg from "../../assets/images/references/adore-a-boo.png";
import PutChiImg from "../../assets/images/references/put-chi.png";
import DivineFoodsImg from "../../assets/images/references/divine-foods.png";
import CleanLeafImg from "../../assets/images/references/clean-leaf.png";
import DirtylImg from "../../assets/images/references/dirtyl.png";
import DisguiseImg from "../../assets/images/references/disguise.png";
import EssencioImg from "../../assets/images/references/essencio.png";
import HabbadaImg from "../../assets/images/references/habbada.png";
import NoraImg from "../../assets/images/references/nora.png";
import TrybImg from "../../assets/images/references/tryb.png";
import YaazhImg from "../../assets/images/references/yaazh.png";
import AaryaImg from "../../assets/images/references/aarya.png";
import BeanoImg from "../../assets/images/references/beano.png";
import FarmsImg from "../../assets/images/references/farms.png";
import HerbImg from "../../assets/images/references/herb.png";
import YellowImg from "../../assets/images/references/yellow.png";
import DearistImg from "../../assets/images/references/dearist.png";
import XystImg from "../../assets/images/references/xyst.png";
import TrueDiamondImg from "../../assets/images/references/true-diamond.png";
import SugarRushImg from "../../assets/images/references/sugar-rush.png";
import FlyingKombuchaImg from "../../assets/images/references/flying-kombucha.png";
import OpulenceImg from "../../assets/images/references/opulence.png";
import ValzarisImg from "../../assets/images/references/valzaris.png";
import BloomBuddyImg from "../../assets/images/references/bloom-buddy.png";
import BodyLavishImg from "../../assets/images/references/body-lavish.png";
import DamazoniaImg from "../../assets/images/references/damazonia.png";
import DoodadImg from "../../assets/images/references/doodad.png";
import FameBazaarImg from "../../assets/images/references/fame-bazaar.png";
import GiverImg from "../../assets/images/references/giver.png";
import IpattiImg from "../../assets/images/references/ipatti.png";
import LabelMyImg from "../../assets/images/references/label-my.png";
import MarpImg from "../../assets/images/references/marp.png";
import RemeImg from "../../assets/images/references/reme.png";
import RoastyTastyImg from "../../assets/images/references/roasty-tasty.png";
import SkcImg from "../../assets/images/references/skc.png";
import VmaxImg from "../../assets/images/references/vmax.png";
import CatozImg from "../../assets/images/references/catoz.png";
import FetchImg from "../../assets/images/references/fetch.png";
import HumpyDumpyImg from "../../assets/images/references/humpy-dumpy.png";
import MsubbuAcademyImg from "../../assets/images/references/msubbu-academy.png";
import OakAgeImg from "../../assets/images/references/oak-age.png";
import SauseImg from "../../assets/images/references/sause.png";
import ArrsImg from "../../assets/images/references/arrs.png";

const References = () => {
  return (
    <div className={ReferencesStyle()}>
      <div className="rn-service-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="References"
                title="Our Valuable Clients"
                description="We had the pleasure of working with a wide range of clients and helped them in their endeavours"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="rn-callto-action clltoaction-style-default style-5">
        <div className="container">
          <div className="row row--0 align-items-center content-wrapper theme-shape">
            <div className="col-lg-12">
              <div className="container">
                <div className="row custom-image-row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={PutChiImg}
                      alt="put-chi.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={ZamaImg}
                      alt="zama.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={DivineFoodsImg}
                      alt="divine-foods.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={MagizhamImg}
                      alt="magizham.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={AdoreABooImg}
                      alt="adore-a-boo.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={JnjpImg}
                      alt="jnjp.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={BrandNuImg}
                      alt="brand-nu.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={AnukulaImg}
                      alt="anukula.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={CleanLeafImg}
                      alt="clean-leaf.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={DirtylImg}
                      alt="dirtyl.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={DisguiseImg}
                      alt="disguise.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={EssencioImg}
                      alt="essencio.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={HabbadaImg}
                      alt="habbada.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={NoraImg}
                      alt="nora.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={TrybImg}
                      alt="tryb.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={YaazhImg}
                      alt="yaazh.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={AaryaImg}
                      alt="aarya.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={BeanoImg}
                      alt="beano.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={FarmsImg}
                      alt="farms.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={HerbImg}
                      alt="herb.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={DearistImg}
                      alt="dearist.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={YellowImg}
                      alt="yellow.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={XystImg}
                      alt="xyst.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={TrueDiamondImg}
                      alt="true-diamond.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={OpulenceImg}
                      alt="opulence.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={SugarRushImg}
                      alt="sugar-rush.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={FlyingKombuchaImg}
                      alt="flying-kombucha.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={ValzarisImg}
                      alt="valzaris.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={BloomBuddyImg}
                      alt="bloom-buddy.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={BodyLavishImg}
                      alt="body-lavish.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={DamazoniaImg}
                      alt="damazonia.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={DoodadImg}
                      alt="doodad.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={FameBazaarImg}
                      alt="fame-bazaar.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={GiverImg}
                      alt="giver.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={IpattiImg}
                      alt="ipatti.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={LabelMyImg}
                      alt="label-my.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={MarpImg}
                      alt="marp.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={RemeImg}
                      alt="reme.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={RoastyTastyImg}
                      alt="roasty-tasty.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={SkcImg}
                      alt="skc.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={VmaxImg}
                      alt="vmax.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={CatozImg}
                      alt="catoz.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={FetchImg}
                      alt="fetch.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={HumpyDumpyImg}
                      alt="humpy-dumpy.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={MsubbuAcademyImg}
                      alt="msubbu-academy.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={OakAgeImg}
                      alt="oak-age.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={SauseImg}
                      alt="sause.png"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <img
                      className="img-fluid custom-ref-image"
                      src={ArrsImg}
                      alt="arrs.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
